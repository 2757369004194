import axios from "axios"


export const numberFormat = num => {
    if (!num) return 0
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
    return new Intl.NumberFormat("en-US", {}).format(num)
  }

export const getStrapiDataFromURL = async url => {
  if (!url) return null

  const getData = () =>
    axios.get(`${process.env.GATSBY_STRAPI_SRC}${url}`, {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
    })
  const res = await getData()

  return res?.data
}