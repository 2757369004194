import { Link } from "gatsby"
import React from "react"
import ImageModule from "../../modules/image-render";
import "./PropertyCard.scss"
import { numberFormat } from "../Common/site/utils";

const PropertyCard = ({ image, price, address, description,slug,id, department, strapi_id,search_type,status,imagetransforms,slider_name, price_qualifier, price_monthly,componentType,bedroom,
  floorarea_min,
  floorarea_type }) => {
  let url="";
  if(search_type==="sales"&&department==="residential"){
    url="/property-for-sale/"
  }
  else if(search_type==="lettings"&&department==="residential"){
    url="/property-to-rent/"
  }
  else if(search_type==="sales"&&department==="new_developments"){
    url="/new-homes-for-sale/"
  }
  else if(search_type==="sales"&&department==="auction"){
    url="/auction-property-for-sale/"
  }

  
  let processedImages;
  if(slider_name==="area-guide-slider"){
    processedImages=imagetransforms?.images_Transforms||JSON.stringify({})
  }
  else{
  processedImages = JSON.parse(imagetransforms)?.images_Transforms||JSON.stringify({})
  }

  let floorarea

  if(floorarea_type==="squareFeet"&&floorarea_min!==0&&floorarea_min){
   floorarea=Math.floor(floorarea_min/ 10.764)
  }

  const imagename="property.images.tileimg"
  return (
    <div className="property-card-section">
      <div className="image-section img-zoom">
        <Link to={`${url}${slug}-${id}/`}>
        {/* <img src={image} alt={description} /> */}
              <ImageModule
                ImageSrc={image}
                altText={`${description}`}
                imagetransforms={processedImages}
                renderer="pic-src"
                imagename={imagename}
                strapi_id={strapi_id}
                classNames="property-card_image"
              />
              {(componentType==="similar-properties"||componentType==="featured-properties")&& 
                <div className="status-flag">
                  <p>{status}</p>
              </div> 
              }
        </Link>
      </div>
      <div className="content">
        <Link to={`${url}${slug}-${id}/`}>
        <p className="address">{address}</p>
        </Link>
        <p className="price">{search_type === "sales" ? `${price_qualifier} £${numberFormat(price)}` : `£${numberFormat(price)} ${price_qualifier?.toUpperCase()} ${price_monthly ? " / £"+parseInt(price_monthly)?.toLocaleString() + " PCM" : ""}` }</p>
        {department==="new_developments"?
          <p className="description new-dev">Development <span>/</span>  {floorarea? <>{`${floorarea} sqm`}<span>/</span></>   :"" } {`${bedroom} ${bedroom>1?"bedrooms":"bedroom"}`}</p>
          :
        <p className="description">{description}</p>
        }
      </div>
    </div>
  )
}

export default PropertyCard
