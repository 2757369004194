import React, { useState, useEffect } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import dummy1 from "../../images/dummy1.png"
import dummy2 from "../../images/dummy2.png"
import dummy3 from "../../images/dummy3.png"
import _ from "lodash"
import PropertyCard from "../PropertyCard/PropertyCard"
// import CustomSlider from "../CustomSlider/CustomSlider"
import CustomSlider from "../CustomSlider/FeatureSlider"
import "./AreaGuideSoldProperties.scss"
import { getStrapiDataFromURL } from "../Common/site/utils"
import ScrollAnimation from "react-animate-on-scroll"

const KEYS = {
  sale: "for-sale",
  rent: "to-rent",
  newHomes: "new-homes",
}

const AreaGuideSoldProperties = (props) => {

  const [saleList, setSaleLists] = useState([])
  const [rentList, setRentLists] = useState([])
  const [key, setKey] = useState(KEYS.sale)


  const getitemsSale = async url => {
    const areaSaleLists = await getStrapiDataFromURL(url)
    if(areaSaleLists.data && areaSaleLists.data.length > 0) {
      setKey(KEYS.sale)
    }
    setSaleLists(areaSaleLists)
  }
  const getitemslet = async url => {
    const areaLetLists = await getStrapiDataFromURL(url)
    if(areaLetLists.data && areaLetLists.data.length > 0) {
      setKey(KEYS.rent)
    }
    setRentLists(areaLetLists)
  }


  useEffect(() => {
    let area = props.PageData?.title ? props.PageData?.title : "";
    let saleUrl = `/api/properties?filters[search_type][$eq]=sales&filters[status][$eq]=SOLD&filters[department][$eq]=residential&filters[publish][$eq]=true&pagination[page]=1&pagination[pageSize]=6&sort=updatedAt:desc`
    if (area) {
      saleUrl = saleUrl + "&filters[area][$eq]=" + area;
    }
    let url_let = `/api/properties?filters[search_type][$eq]=lettings&filters[status][$eq]=Let&filters[department][$eq]=residential&filters[publish][$eq]=true&pagination[page]=1&pagination[pageSize]=6&sort=updatedAt:desc`; // TODO: base URL supposed to be from .env
    if (area) {
      url_let = url_let + "&filters[area][$eq]=" + area;
    }

    getitemsSale(saleUrl)
    getitemslet(url_let)

  }, [])


  useEffect(() => {
    // setTimeout(() => {
      let getClass = document.querySelector(".featured-properties-container")
      if (!getClass) return

      let getId = document.getElementById("footer-component")
      const { offsetLeft } = getId

      let padding;

      padding = `${offsetLeft}px`

      getClass.style.paddingLeft = padding
      // slickDots.style.position = "relative"

    // }, 2500)
  }, [saleList, rentList])

  const SliderTab = ({ items }) => {

    if (_.isEmpty(items)) return null
    return (
      <CustomSlider className="featured-slider-wrap">
        {items.data.map((property, i) => (
          <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i + 1) * 100} offset={25}>
            <PropertyCard
              address={property.attributes?.address?.display_address}
              description={property.attributes?.title}
              image={property.attributes?.images[0]}
              price={property.attributes?.price}
              slug={property.attributes?.slug}
              imagetransforms={property.attributes?.imagetransforms}
              id={property?.attributes?.crm_id}
              strapi_id={property.id}
              search_type={property.attributes?.search_type}
              status={property.attributes?.status}
              price_qualifier={property.attributes?.price_qualifier}
              price_monthly={property?.attributes?.extra?.price_monthly}
              slider_name="area-guide-slider"
              department={property.attributes?.department}
            />
          </ScrollAnimation>
        )
        )}
      </CustomSlider>
    )
  }

  return (rentList?.data?.length > 0 || saleList?.data?.length > 0 ?
    <section className={`${props.page == "areaguide" ? "featured-properties-section areaguide-properties-section" : "featured-properties-section"}`}>
      <div className="featured-properties-container">
        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
          <h2 className="title">{props?.title}</h2>
          <Tabs
            activeKey={key}
            onSelect={k => setKey(k)}
            className="featured-properties-tab-section"
          >
            {saleList?.data?.length > 0 &&
              <Tab eventKey={KEYS.sale} title="Sold">
                <SliderTab items={saleList} />
              </Tab>
            }
            {rentList?.data?.length > 0 &&
              <Tab eventKey={KEYS.rent} title="Let">
                <SliderTab items={rentList} />
              </Tab>
            }
          </Tabs>
        </ScrollAnimation>
      </div>
    </section> : null
  )
}
export default AreaGuideSoldProperties
