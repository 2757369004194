import React from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { PageLinks } from "../common/site/page-static-links";
import Seo from "../components/seo"
import AreaGuideSoldProperties from "../components/AreaGuideSoldProperties/AreaGuideSoldProperties";
import LocRatingMap from '../components/map/loc-rating-map';
import { MapMarkerInfoBox } from '../components/map/map-marker'
import { homeValuation, instantValuation } from "../site/urls";
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const AreaGuideDetailDesc = loadable(() => import("../components/AreaGuideDetailDesc/AreaGuideDetailDesc"));

const AreaGuideDetail = ({ data }, props) => {
    const PageData = data?.strapiAreaGuide
    let mapItems = [];
    let mapItem = {};
    mapItem['lat'] = PageData?.latitude
    mapItem['lng'] = PageData?.longitude
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push(mapItem);
    const sidebarData = data?.strapiGlobalMenu
    const metaTitle=`${PageData.title} Area Guide`;
    const metaDescription=`Know more about ${PageData.title} here. Contact one of our property experts for assistance in finding the right property for you in ${PageData.title}.`
    return (
        <Layout popularSearch="static-details">
           <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
           {/* <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
     </div>
            <Seo title={metaTitle} description={metaDescription} />
            <div className="layout-padding-top">
                <BreadcrumbModule mainparentname={`About`} mainparent={PageLinks.about} parentname={`Area Guides`} parent={PageLinks.areaguide} pagename={PageData?.title} />
                <AreaGuideDetailDesc sidebarData={sidebarData}  {...PageData} />
                {PageData?.latitude && PageData?.longitude &&
                    <div className="area-guide-map">
                        <LocRatingMap data={mapItems} />
                    </div>
                }
                <AreaGuideSoldProperties page="areaguide" title={`Sold Properties in ${PageData?.title}`} PageData={PageData} />
            </div>
        </Layout>
    )
}

export default AreaGuideDetail



export const query = graphql`
query ($page_id: Int) {
    strapiAreaGuide(strapi_id: {eq: $page_id}) {
        ...AreaGuideFragment
        banner_image {
          url
        }
        imagetransforms{
            banner_image_Transforms
            module_image_Transforms
        }
        add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_CONTENT_BLOCK {
                strapi_component
                content {
                    data {
                      content
                    }
                  }
            }
            ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK {
                strapi_component         
                image {
                    url
                }
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
                strapi_component
                add_toggle {
                    title
                    content {
                      data {
                        content
                      }
                    }
                  }
                  
            }
        }
    }
    
    strapiGlobalMenu {
        strapi_id
        imagetransforms {
          sidebar_image_Transforms
      }
        areaguide_bar {
            title
            content {
              data {
                content
              }
            }
            image {
              url
            }
            add_link {
              label
              link {
                slug
                strapi_parent {
                  slug
                  strapi_parent {
                    slug
                  }
                }
              }
            }
          }
    }
   
  }
`